import { useEffect, useRef, useState } from "react"

import useResizeWindow from "@features/Graphs/hooks/useResizeWindow.ts"

import ActivityGraphD3 from "./D3Files/ActivityGraphD3.js"
import DailyHeartRateGraphD3 from "./D3Files/ActivityGraphWithMinMaxD3.js"

interface ActivityGraphProps {
  graphData?: ThumbnailGraphData[]
  name?: string
  color?: string
  useMinMax?: boolean
}

const ActivityGraph = ({
  graphData = [],
  name = "Daily Graph",
  color = "#5E89FF",
  useMinMax = false,
}: ActivityGraphProps) => {
  const chartArea = useRef<HTMLDivElement>(null)
  const containerRef = useRef<HTMLDivElement>(null)
  const currentGraphData = graphData
  const containerSize = useResizeWindow(containerRef)
  const isValidSize = containerSize.width > 0 && containerSize.height > 0
  const hasValidData = graphData?.length > 0
  const [showChart, setShowChart] = useState(true)

  useEffect(() => {
    if (isValidSize && hasValidData && !useMinMax) {
      new ActivityGraphD3(chartArea.current, containerSize.width, containerSize.height, currentGraphData, color)
    } else if (isValidSize && hasValidData && useMinMax) {
      new DailyHeartRateGraphD3(chartArea.current, containerSize.width, containerSize.height, currentGraphData, color)
    }
  }, [containerSize, graphData, currentGraphData, isValidSize, hasValidData, color, useMinMax, showChart])

  const isHidden = !showChart && "hidden"
  const getHiddenStyle = () => !showChart && "text-neutral-100"
  const getHiddenText = () => !showChart && " (hidden)"

  return (
    <div className="w-full h-auto overflow-hidden select-none">
      <div className="flex flex-row justify-between items-start">
        <div className={`text-xl ${getHiddenStyle()}`}>
          {name}
          {getHiddenText()}
        </div>
        <button
          className="text-sm text-neutral-300 border px-2 rounded-full min-w-20 hover:bg-neutral-100"
          onClick={() => setShowChart(!showChart)}
        >
          {showChart ? "Hide" : "Show"}
        </button>
      </div>
      <div className={`w-full h-auto overflow-hidden ${isHidden}`} ref={containerRef}>
        <div className="chart-area flex-1 w-full h-48 overflow-hidden" ref={chartArea}></div>
      </div>
    </div>
  )
}

export default ActivityGraph
