import ActivityGraph from "../graph/ActivityGraph"
import useHRVSummaryStore from "../summary/store/HRVSummaryStore"
import useHeartRateSummaryStore from "../summary/store/HeartRateSummaryStore"
import DailyHRVChartProvider from "./provider/DailyHRVChartProvider"
import DailyHeartRateChartProvider from "./provider/DailyHeartRateChartProvider"

const DailySleepAndStepsCard: React.FC = () => {
  const { currentHRVResponse } = useHRVSummaryStore()
  const hrvChartData = currentHRVResponse?.chart_data || []

  const { currentHeartRateResponse } = useHeartRateSummaryStore()
  const heartRateChartData = currentHeartRateResponse?.chart_data || []

  return (
    <div className="flex gap-3 flex-col">
      <DailyHeartRateChartProvider>
        <ActivityGraph graphData={heartRateChartData} name="Heart Rate" color="#F85884" useMinMax={true} />
      </DailyHeartRateChartProvider>
      <DailyHRVChartProvider>
        <ActivityGraph graphData={hrvChartData} name="HRV" color="#F85884" />
      </DailyHRVChartProvider>
    </div>
  )
}

export default DailySleepAndStepsCard
