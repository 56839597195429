import { create } from "zustand"

interface ExerciseSummaryStore {
  currentExerciseResponse: ExerciseSummaryResponse | null
  setCurrentExerciseResponse: (response: ExerciseSummaryResponse) => void
  currentOffsetDays: number
  setCurrentOffsetDays: (offsetDays: number) => void
  currentLimitDays: number
  setCurrentLimitDays: (limitDays: number) => void
}

const useExerciseSummaryStore = create<ExerciseSummaryStore>((set) => ({
  currentExerciseResponse: null,
  setCurrentExerciseResponse: (currentExerciseResponse) => set({ currentExerciseResponse }),

  currentOffsetDays: 0,
  setCurrentOffsetDays: (currentOffsetDays) => set({ currentOffsetDays }),

  currentLimitDays: 30,
  setCurrentLimitDays: (currentLimitDays) => set({ currentLimitDays }),
}))

export default useExerciseSummaryStore
