import useHomeTimeSummaryStore from "../summary/store/HomeTimeSummaryStore"
import useSleepSummaryStore from "../summary/store/SleepSummaryStore"
import useStepSummaryStore from "../summary/store/StepSummaryStore"
import CardioSection from "./CardioSection"
import LocationSection from "./LocationSection"
import SleepAndStepsSection from "./SleepAndStepsSection"
import useActivityInsightStore from "./store/ActivityInsightStore"

const ActivityPage: React.FC = () => {
  const setHomeTimeOffsetDays = useHomeTimeSummaryStore((state) => state.setCurrentOffsetDays)
  const setSleepOffsetDays = useSleepSummaryStore((state) => state.setCurrentOffsetDays)
  const setStepOffsetDays = useStepSummaryStore((state) => state.setCurrentOffsetDays)
  const setActivityOffsetDays = useActivityInsightStore((state) => state.setCurrentOffsetDays)

  setHomeTimeOffsetDays(0)
  setSleepOffsetDays(0)
  setStepOffsetDays(0)
  setActivityOffsetDays(0)

  return (
    <div className="flex flex-col items-center w-full">
      <div className="w-full">
        <h3>Activity</h3>
      </div>

      <SleepAndStepsSection />
      <CardioSection />
      <LocationSection />
    </div>
  )
}

export default ActivityPage
