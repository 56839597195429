import { create } from "zustand"

interface HRVSummaryStore {
  currentHRVResponse: HRVSummaryResponse | null
  setCurrentHRVResponse: (response: HRVSummaryResponse) => void
  currentOffsetDays: number
  setCurrentOffsetDays: (offsetDays: number) => void
  currentLimitDays: number
  setCurrentLimitDays: (limitDays: number) => void
}

const useHRVSummaryStore = create<HRVSummaryStore>((set) => ({
  currentHRVResponse: null,
  setCurrentHRVResponse: (currentHRVResponse) => set({ currentHRVResponse }),

  currentOffsetDays: 0,
  setCurrentOffsetDays: (currentOffsetDays) => set({ currentOffsetDays }),

  currentLimitDays: 30,
  setCurrentLimitDays: (currentLimitDays) => set({ currentLimitDays }),
}))

export default useHRVSummaryStore
