import Card from "@/components/Cards/Card.tsx"

import SectionContainer from "../atom/SectionContainer"
import ActivityRangeSelector from "./ActivityRangeSelector"
import DailySleepAndStepsCard from "./DailySleepAndStepsCard"
import SleepAndStepsCard from "./SleepAndStepsCard"
import ActivityStackGraphProvider from "./provider/ActivityStackGraphProvider"

const SleepAndStepsSection: React.FC = () => {
  const cardStyle = "border w-full shadow-none"

  return (
    <SectionContainer
      title="Sleep, Steps & Exercise"
      description="Time is adjusted to the client's timezone"
      headerControls={<ActivityRangeSelector />}
    >
      <div className="flex gap-3 flex-col mt-3">
        <Card additionalClasses={cardStyle}>
          <DailySleepAndStepsCard />
          <ActivityStackGraphProvider>
            <SleepAndStepsCard />
          </ActivityStackGraphProvider>
        </Card>
      </div>
    </SectionContainer>
  )
}

export default SleepAndStepsSection
